<template>
  <div class="card" v-if="alerts.data != null">
    <div class="card-body">
      <div class="form-group mb-0">
        <input
          type="search"
          class="form-control"
          @change="SearchalertText"
          v-bind="searchText"
          placeholder="Search"
        />
      </div>
    </div>
    <table class="table table-striped">
      <thead>
        <tr>
          <th>ID</th>
          <th>Name</th>

          <th>Administrator</th>

          <th>Email</th>
          <th>Phone</th>
          <th>Location</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="p in alerts.data" :key="p.Id">
          <td>{{ p.Id }}</td>
          <td>
            <router-link :to="`/alerts/${p.Id}`">{{ p.Name }} </router-link>
          </td>
          <td>{{ p.Administrator }}</td>
          <td>{{ p.Email }}</td>
          <td>{{ p.Phone }}</td>
          <td>{{ p.Location }}</td>

          <td>
            <router-link :to="`/alerts/${p.Id}`"
              ><i class="fa fa-user actionIcons"></i>
            </router-link>
            <router-link :to="`/alerts/${p.Id}`"
              ><i class="fa fa-calendar actionIcons" aria-hidden="true"></i>
            </router-link>
            <router-link :to="`/alerts/${p.Id}`"
              ><i class="fas fa-envelope actionIcons"></i>
            </router-link>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "ListClientAlerts",

  data() {
    return {
      searchText: "",
    };
  },
  computed: {
    ...mapState(["alerts"]),
  },
  mounted() {
    this.$store.dispatch("alerts/get");
  },

  methods: {
    SearchalertText() {
      return;
      // this.$store.dispatch("alerts/search", this.searchText);
    },
  },
};
</script>
